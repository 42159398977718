//design for the new quote component
@font-face {
  font-family: "MainFont";
  src: url("../fonts/SinkinSansLight.ttf");
}

input[type="number"] {
  -moz-appearance: textfield;
}

input {
  padding: 0.5rem;
}

.per7 {
  width: 6%;
  max-width: 6%;
}

.per5 {
  width: 5%;
  max-width: 5%;
}

.per20 {
  width: 20%;
  max-width: 20%;
}

.per10 {
  max-width: 10%;
  width: 10%;
}

body {
  font-family: MainFont, Arial, Georgia, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  // font-size: 13.7px;
  font-size: 12.8px;
}

.custom-card {
  box-shadow: 0 4px 8px 0 rgba(135, 129, 129, 0.5);
  transition: 0.3s;
  width: 40%;
}

.custom-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

class-desc-select .class-desc-select__menu {
  height: fit-content;
  width: fit-content;
  max-width: 500px;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
}

.contentHolder-history {
  min-height: calc(100vh - 15.75rem);
}

@mixin cmprQts {
  //style of the background image:
  background-image: url("../images/backimage4.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-attachment: sticky;

  .quote-table {
    .computation {
      table {
        tbody {
          td,
          th {
            border-top: 0 !important;
          }
          tr {
            td:nth-child(2) {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .form-label {
        margin-bottom: 0 !important;
      }
    }
    .quote-table-company-name {
      padding-right: calc(2rem + 25px);
    }
    .head-row {
      align-items: center;
      justify-content: space-between;
    }
    .hr1 {
      color: #2c2e33;
      font-size: 26px;
      font-weight: 500;
      &.quote-table-company-name {
        font-family: "Overpass", sans-serif;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .hr2 {
      color: #2c2e33;
      width: 70%;
      margin-top: 1rem;
      margin-bottom: 1rem;
      span {
        color: #5f636b;
      }
    }
    .hr3 {
      margin-bottom: 1.5rem;
      color: #2c2e33;
    }
    .body-row {
      padding-left: 6px;
      margin-top: 2rem;
      // justify-content: left;
      .table {
        margin-bottom: 3rem;
        position: relative;
        right: 2rem;
        border-collapse: separate;
        border-spacing: 25px 0px;

        thead {
          th {
            color: #2c2e33;
            position: relative;
            // right: 0.6rem;
            // font-size: 16px;
            font-size: 18px;
          }
        }
        tbody {
          padding-left: 5px;
          padding-right: 5px;
          font-size: 18px;
          tr {
            box-shadow: none;
          }
          td,
          th {
            width: 20%;
            border: 1px solid white;
            background-color: white;
            color: #2c2e33;
            font-size: 18px;
            font-weight: normal;
            div {
              height: 100%;
              label {
                margin-bottom: 0;
              }
            }
          }
          td {
            label {
              font-size: 18px;
              // font-size: 22px;
              font-weight: 800;
              color: #1463ac;
              margin-bottom: 0;
            }
            .best {
              font-size: 14px;
              margin-left: 1rem;
              color: #038b00;
            }
            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }
            .uw {
              font-size: 14px;
              margin-left: 1rem;
            }
            input {
              margin: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.new-navbar {
  // margin-bottom: 1rem;
  // height: 4.75rem;
  background-color: white;
  color: white;
  font-weight: 600;
  font-size: 16px;
  .pride-logo {
    margin-left: 2.2rem;
  }
  .peo-header {
    color: #ff5a54;
  }
  .insurecomp-logo {
    margin-right: 2.2rem;
  }
  .forBig {
    margin-left: auto;
  }

  .insurecomp-image {
    margin-top: 1rem;
    height: 5.6rem;
    margin-bottom: 1rem;
  }

  .extensis-image {
    margin-top: 1rem;
    height: 2.5rem;
    margin-bottom: 1rem;
  }

  .boxed-logo {
    background-color: #fff;
    padding: 20px;
    height: 125px;
    margin: 5px 20px;
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-left: 40px;
  }

  button {
    border: none;
    color: white;
    background: none;
    font-weight: 600;
  }

  span {
    // color: white;
    font-weight: 600;
  }

  ul {
    .upload-guide a {
      color: #007bff;
      // text-decoration:;
    }
    li {
      // padding-bottom: 10px;
      margin-right: 1rem;
      margin-left: 1rem;
      a {
        color: #343a40;
      }
    }
  }
  .navbar_responsive {
    display: flex;
    flex-direction: row;
  }
}

.secDesc {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
}

#small-loader {
  width: fit-content;
  height: fit-content;
  /* margin-top: 1rem; */
  > img {
    width: 2rem;
  }
}

#rate1,
#rate2 {
  margin-bottom: 5px;
}

#compareQuote {
  .quote-table {
    .form-control,
    .input-group-text,
    .input-group button,
    .input-group .switch,
    .input-group .btn {
      font-size: 12.8px;
    }
    .price-modifier,
    .rate-match {
      .rate-match-switch {
        .switch-on,
        .switch-off {
          background-color: #fcac00;
          color: #212529;
        }
      }
      .rate-match-class-code {
        .table-wrapper {
          max-height: 400px;
        }
        table {
          font-size: 12px;
        }
        th {
          background-color: rgb(20, 99, 172);
          color: white;
          text-align: center;
          vertical-align: middle;
          position: sticky;
          top: 0;
        }
        td {
          white-space: nowrap;
          // width: 50%;
          text-align: center;
          vertical-align: middle;
          input {
            width: 80px;
          }
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }
        }
        .text-center {
          background-color: white;
          border-radius: 20px;
          border: 2px solid black;
        }
      }
      .form-label {
        margin-bottom: 0 !important;
      }
      .action-buttons {
        .calculate-button {
          background-color: #027001;
        }
        .sendToSalesforce-button {
          background-color: #fcac00;
        }
      }
      .section-header {
        .header-name {
          color: black;
          margin: 0;
          font-size: 23px;
          font-weight: bolder;
        }
        padding: 0.4rem 1rem;
        // background-color: #5c8ec4;
      }
      .section-body {
        .section-form {
          .input-group {
            .rateMatchOverallInput {
              border-radius: 0 0 0 20px;
            }
            .input-group-append {
              border: none;
              box-shadow: 0.5px 1px 4px grey;
              border-radius: 0 20px 20px 0;
              button,
              .excessRiskMarginappend {
                border-radius: 0 20px 20px 0;
              }
            }
            .input-group-prepend {
              border-radius: 0 0 0 20px;
              button {
                border-radius: 0 0 0 20px;
              }
            }
          }
        }
      }
    }
    .price-modifier {
      .section-body {
        .section-form {
          width: 80%;
          .input-group {
            div {
              border-radius: 0 0 0 20px;
            }
            .input-group-append {
              border: none;
              box-shadow: 0.5px 1px 4px grey;
              border-radius: 0 20px 20px 0;
              .input-group-text {
                border-radius: 0 20px 20px 0;
              }
            }
          }
        }
      }
    }
    .rate-match {
      .excess-risk-margin {
        label {
          padding: 4px 0;
          background-color: #fcac00;
        }
      }
    }
    .tabs-section {
      .tabs-headers {
        font-size: 16px;
        margin-bottom: 2rem;
        > .nav-item {
          cursor: pointer;
          padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        > .active {
          background-color: #9abfe2;
          color: black;
          border-radius: 20px;
          margin: 5px;
        }
        .nav-link {
          padding: 1px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      .tabs-body {
        td {
          text-align: center;
        }
        .left-header {
          text-align: left;
        }
        .main-header {
          color: white;
          background-color: #1463ac;
          vertical-align: middle;
          text-align: center;
        }
        .sub-header {
          background-color: #b2d5f3;
        }
      }
    }
    #statements {
      background-color: #ffeed5;
      height: 450px;
      padding-right: 10px;
    }
  }
  .btnSubmits {
    margin-top: 2rem;
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #f0ad4e;
    border-color: #eea236;
    // background-image: linear-gradient(to right, #10c03e, #0eaf84);
    box-shadow: none;
    // border-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 800;
    // margin-top: 30px;
    // margin-bottom: 36px;
  }
  .btnView {
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #1463ac;
    margin-right: 1rem;
    margin-top: 2rem;
    font-size: 14px;
    font-weight: 800;
  }
  .input-group-append {
    border: none;
    box-shadow: 0.5px 1px 4px grey;
  }
  .excess-risk-margin {
    .input-group-prepend {
      border: none;
      box-shadow: 0.5px 1px 4px grey;
    }
  }
  .section-form {
    input:focus-visible,
    select:focus-visible {
      outline: 2.2px solid black; /* Change the color to black */
      border-radius: 4px;
    }
  }
  .computation {
    .form-group {
      select:focus-visible {
        outline: 2.2px solid black; /* Change the color to black */
        border-radius: 4px;
      }
    }
  }
}

.errMsg {
  color: red;
}

.form-control {
  box-shadow: 0.5px 1px 4px grey;
  border-radius: 0 20px 20px 20px;
}
.form-control:focus {
  border: none !important;
  outline: none; /* Remove the default focus outline */
  box-shadow: none; /* Remove the blue shadow on focus */
}

#rateMatchTarget,
#finalRatingFactor {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.newquote-mainbody {
  //global styles for quote main body
  background-color: #e6edf0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  .forSmall {
    display: none;
  }

  .newquote-content {
    //global styling for everything in the light blue background

    padding-left: 1.5rem;
    padding-right: 1.6rem;
    width: 90%;
    margin-top: 1rem;
    padding-bottom: 2rem;
    background-color: #ececec;
    border-radius: 20px;

    .emodDate,
    // form select,
    form input[type="text"],
    form input[type="email"],
    form input[type="password"],
    form input[type="date"],
    form textarea {
      width: 100%;
      font-size: 0.85em;
      border-radius: 4px;
      border: 1px solid white;
      background: white;
      color: #2b2d31;
      font-weight: "normal";
      &:hover {
        // border: 0px;
      }
    }

    input[type="checkbox"] {
      height: 16px !important;
      width: 16px;
      border-radius: 4px;
      border: solid 1px #979797;
      background-color: #ffffff;
    }

    //styling of the headers and section-headers:
    // this is styling for all the different stages
    // for changing any heading, please refer to this section only
    .formTitle {
      color: #2c2e33;
      // font-family: "Rubik", sans-serif;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.38;
      &.location-details-heading {
        font-weight: 400;
        font-size: 1.2rem;
      }
      &.compName {
        font-family: Overpass;
        font-size: 20px;
        font-weight: 600;
        color: #2c2e33;
        // padding-left: 4rem;
      }
      &.title2 {
        width: 70%;
        display: inline-block;
      }
      &.title3 {
        font-size: 20px;
        font-weight: normal;
      }
    }
    .addressRate,
    .aggregate-heading,
    .stateAggName {
      font-family: Overpass;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2d31;
    }
    //end of heading/subheading styles

    //styling of buttons:
    .transpButton {
      background-color: transparent;
      border-color: transparent;
    }

    .btnView {
      width: 210px;
      height: 40px;
      border-radius: 5px;
      background-color: #1463ac;
      margin-right: 1rem;
      margin-top: 2rem;
      font-size: 14px;
      font-weight: 800;
    }

    .proceed-bind {
      border-radius: 7.2px;
      background-color: #f0ad4e;
      border-color: #eea236;
      // background-image: linear-gradient(to right, #10c03e, #0eaf84);
      // box-shadow: none;
      box-shadow: 2px 4px 12px #eea236;
      // border-color: transparent;
      color: white;
      font-size: 1rem;
      font-weight: 800;
    }

    .btnSubmits {
      margin-top: 2rem;
      // width: 210px;
      // height: 40px;
      border-radius: 7.2px;
      background-color: #f0ad4e;
      border-color: #eea236;
      // background-image: linear-gradient(to right, #10c03e, #0eaf84);
      // box-shadow: none;
      box-shadow: 2px 4px 12px #eea236;
      // border-color: transparent;
      color: white;
      font-size: 1rem;
      font-weight: 800;
    }
    // end of button styling

    //style for the progress bar at the top of every page
    // please refer to this section only for styling the progress bar
    .color-bar {
      margin-top: 1.5rem;
      margin-bottom: 44px;
      // margin-left: 1.2rem;
      width: 100%;
      height: 12px;
      border-radius: 8px;
      background-image: linear-gradient(to right, #7d8fec, #2fe6db);
      span {
        display: block;
        height: 100%;
        background-color: #0dd209;
        border-radius: 8px;
      }
    }
    // end of progress bar styles
    //style for company profile tab
    #paymentQuote {
      span.errMsg {
        color: red;
        font-size: 11px;
      }
    }
    #compProfile {
      // background-image: url("../images/backimage1.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;
      margin-top: 20px;
      .information-statements {
        margin-bottom: 38px;
        ul {
          padding-left: 15px;
          li {
            color: red;
          }
          :not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
      #claimTable {
        .form-group {
          margin-bottom: 2px;
        }
        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }
        color: #919399;
        font-weight: "normal";
        border-collapse: separate;
        border-spacing: 20px 0px;
        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;
            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }
      .formTitle {
        margin-bottom: 34px;
      }
      .company-locations,
      .history-header,
      .emod-ques-heading {
        margin-bottom: 30px;
      }
      .mandate {
        color: #919399;
      }
      small {
        color: #333333;
      }
      .form-group {
        margin-bottom: 38px;
        select,
        input {
          height: 35px;
          color: #2b2d31;
          // font-size: 13.7px;
          font-size: 12.8px;
          padding-left: 5px;
          box-shadow: 0.5px 1px 4px grey;
          border-radius: 0 20px 20px 20px;
        }
        input:focus-visible,
        select:focus-visible {
          outline: 2px solid black; /* Change the color to black */
        }
        #emailAddress {
          font-size: 13px;
        }
        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // font-size: 13.7px;
          font-size: 12.8px;
          color: #333;
          margin-bottom: 0;
          &.mandatory::after {
            content: "*";
            padding-left: 2px;
            color: red;
          }
        }
        p.errMsg {
          color: red;
          font-size: 11px;
        }
        .react-datepicker-wrapper {
          width: 100%;
          .react-datepicker__input-container {
            width: 100%;
            input {
              width: 100%;
            }
            .startDateWithExtensis {
              width: 75%;
            }
          }
        }
      }
      textarea {
        padding-left: 5px;
        height: 123px;
      }

      #allLocations {
        margin-top: 0.7rem;
      }
      .emod-ques,
      .historical-claims {
        margin-top: 3rem;
      }
      .emod-ques {
        .emodDate {
          padding: 0 !important;
          .react-datepicker-wrapper {
            width: 100%;
            .react-datepicker__input-container {
              width: 100%;
            }
          }
        }
        .tableRemarks {
          .ques-input {
            border-radius: 0 20px 20px 20px;
          }
          input {
            box-shadow: 0.5px 1px 4px grey !important;
            border-radius: 0 20px 20px 20px;
          }
        }
      }
      .address-delete {
        margin-top: 1.2rem;
      }
      .linkLike {
        position: relative;
        bottom: 20px;
      }
      #otpSendMsg,
      #otpSentMsg,
      #otpVerify,
      #verifySuc {
        display: none;
        font-size: 0.8em;
      }
      #otpSec,
      #resendOTP {
        display: none;
      }
      #emailStatus,
      #verifyStatus {
        margin-top: 1rem;
      }
      #timerOTP {
        font-size: 0.8rem;
      }
      .btn-link {
        padding-left: 0;
      }
    }

    #cmprRate {
      //style block for compare rate page/tab
      //styling for the background image
      background-image: url("../images/backimage2.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 100%;
      background-attachment: sticky;

      // .download-link-div {
      //   width: 20px;
      // }

      .formTitle {
        margin-bottom: 60px;
      }

      .title3 {
        margin-bottom: 25px;
      }

      .code-delete {
        // margin-top: 0.25rem;
      }

      .linkLike {
        margin-bottom: 37px;
      }

      .calcPremium {
        color: #919399;
        input {
          height: 36px;
          color: #2b2d31;
          font-size: 14px;
          padding-right: 5px;
        }
      }

      .rangeSlider {
        padding: 1.2rem;
        .row {
          // justify-content: space-around;
          margin: 0;
          width: 100%;
        }
        .premVal {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35%;
          height: 35px;
          border-radius: 20px;
          background-color: #2987de;
          font-size: 20px;
          font-weight: 800;
          color: #ffffff;
          span {
            position: relative;
            top: 2px;
          }
        }
        .barBG {
          margin-left: 1rem;
          margin-right: 1rem;
          margin-top: 1rem;
          width: 12px;
          height: 2px;
          background-color: #2c2e33;
        }
      }

      #attachSec {
        margin-top: 47px;
        .no-lossrun-affidavit-heading {
          color: #2b2d31;
          font-weight: "normal" !important;
          font-size: 14px;
        }
        .dropped-file {
          color: #4169ba;
        }
        .drop-zone {
          margin-top: 20px;
          margin-bottom: 44px;
          display: flex;
          background: white;
          opacity: 0.5;
          justify-content: center;
          align-items: center;
          height: 106px;
          width: 385px;
          border: 1px dashed black;
          span {
            color: #1463ac;
          }
        }
        .drop-info {
          margin-top: 20px;
        }
      }

      .grey-table-row {
        position: relative;
        right: 7px;
      }

      #claimTable {
        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }
        color: #919399;
        font-weight: "normal";
        border-collapse: separate;
        border-spacing: 20px 0px;
        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;
            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }
      .btnSubmits {
        margin-top: 30px;
        margin-bottom: 36px;
      }
      .form-group {
        select,
        input {
          height: 35px;
          color: #2b2d31;
          font-size: 14px;
          padding-left: 5px;
        }
        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 11px;
          color: #333;
          margin-bottom: 0;
          &.mandatory::after {
            content: "*";
            padding-left: 2px;
            color: red;
          }
        }
        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }
      #claimTable {
        .form-group {
          margin-bottom: 2px;
        }
      }
    }

    #undrQues {
      // style for the background image:
      background-image: url("../images/backimage3.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      //style for the unerwriter questions and additional questions table:
      .heading-row {
        justify-content: space-between;
        .compName {
          margin-top: 2px;
          color: #2c2e33;
          font-size: 20px;
          font-weight: 600;
        }
      }

      .title2 {
        margin-bottom: 50px;
      }
      .table-addnques,
      .table-undrques {
        margin-bottom: 3.5rem;
        thead {
          color: #2c2e33;
          font-size: 1.2rem;
          tr {
            th {
              border: none;
              &.tableQues {
              }
              &.tableAns {
              }
              &.tableRem {
              }
            }
          }
        }
        tbody {
          tr {
            td {
              vertical-align: middle;
              padding-top: 5px;
              padding-bottom: 5px;
              &.tableAns {
                .radio-btn-no {
                  margin-left: 2.5rem;
                  width: 18px;
                  height: 18px;
                }
                .radio-btn-yes {
                  margin-left: 1rem;
                  width: 18px;
                  height: 18px;
                }
                .radiobtn {
                  margin-left: 4px;
                  position: relative;
                  bottom: 4px;
                }
              }
              &.tableQues {
                width: 60%;
              }
              &.tableRemarks {
                padding-right: 5px;
                input {
                  height: 32px;
                  border: 1px solid #aaa;
                }
                .hidden {
                  background-color: rgba(131, 165, 191, 0.2);
                  border: none;
                }
              }
              &.table-question {
                margin: 10px;
              }
            }

            &:nth-child(odd) {
              // margin-left: -20px;
              background-color: #ffffff;
              opacity: 0.7;
              .tableRemarks {
              }
            }
          }
        }
      }
    }
    .additional-questions {
      margin-bottom: 30px;
    }
    .table-undrques {
      margin-bottom: 2rem;
      tbody {
        .trow1 {
          padding-top: 5px !important;
          padding-bottom: 5px !important;
        }
      }
    }
    .table-addnques {
      .tableAns {
        width: 18%;
      }
    }
    .btn-undrques {
      margin-bottom: 2rem;
    }

    #cmprQts {
      @include cmprQts;
      margin-top: 20px;
    }

    //styling of review and pay page:
    #payBind {
      input,
      select {
        height: 40px;
      }
      .formTitle {
        margin-bottom: 37px;
      }

      #carrierName {
        font-size: 16px;
      }
      background-image: url("../images/backimage5.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;
      .row-formTitle {
        justify-content: space-between;
        .formTitle {
          margin-bottom: 0rem;
        }
      }
      .row-acord-preview-heading {
        justify-content: space-between;
        .col-acord-text {
          margin-left: 16px;
        }
        .col-acord-download {
          margin-left: 15px;
          padding-left: 36px;
        }
        #accordLink {
          color: #0973d5;
        }
        img {
          margin-right: 1rem;
        }
      }
      .row-acord-preview {
        margin-top: 1rem;
        justify-content: center;
        margin-bottom: 3.5rem;
        iframe {
          border: none;
          width: 78%;
          height: 30rem;
        }
      }
      .pdf-upload-placeholder {
        width: 78%;
        height: 30rem;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
        margin-bottom: 81px;
        .pdf-loader-gif {
          margin-left: 1.5rem;
        }
      }
      .row-tnc {
        margin-top: 1rem;
        font-size: 16px;
        color: #2c2e33;
        p {
          margin-top: 0.6rem;
        }
        .payPageItems2 {
          margin-left: 2rem;
        }
        .linkLike {
          margin-left: 0.5rem;
        }
      }
    }

    //styling for bind page
    #binderForm {
      .linkLike {
        margin-top: 60px;
        margin-bottom: 36px;
      }
      .btnGen {
        margin-bottom: 10px;
      }
      .col-sm-12 {
        .formTitle {
          margin-bottom: 1rem;
        }
        .row-binder-heading {
          padding-right: 10px;
          padding-left: 10px;
          justify-content: space-between;
        }
        .row-iframe {
          justify-content: center;
          width: 100%;
          .col-iframe {
            padding-left: 25px;
            padding-right: 25px;
            width: 100%;
            .acord {
              justify-content: flex-end;
              width: 95%;
              color: #0973d5;
              span {
                position: relative;
                top: 4px;
                margin-left: 0.5rem;
              }
            }
            .row-iframe-preview {
              width: 100%;
              justify-content: center;
              iframe {
                // margin-top: 2rem;
                border: none;
                width: 90%;
                height: 45rem;
              }
            }
          }
        }
      }
    }

    .class-desc-select {
      background-color: white;
      border-radius: 0 20px 20px 20px;
      div {
        height: 35px;
        padding-top: 0;
        padding-bottom: 0;
      }
      .class-desc-select__indicator {
        align-items: center;
      }
      .class-desc-select__menu {
        height: fit-content;
        width: fit-content;
        max-width: 350px;
        min-width: -webkit-fill-available;
        min-width: -moz-available;
        .class-desc-select__menu-list {
          height: fit-content;
          max-height: 150px;
          .class-desc-select__option {
            display: flex;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: fit-content;
          }
        }
      }
      .class-desc-select__placeholder {
        margin: 0;
        position: unset;
        transform: none;
        height: fit-content;
      }
      .class-desc-select__control {
        // min-height: 35px;
        // border: 1px solid #ababab;
        // background: transparent;
        // border-radius: 4px;
        border: none !important;
        box-shadow: 0.5px 1px 4px grey;
        border-radius: 0 20px 20px 20px;
        .class-desc-select__value-container {
          overflow: initial;
          .class-desc-select__single-value {
            height: fit-content;
          }
          .class-desc-select__input {
            input {
              box-shadow: none !important;
            }
          }
        }
      }
      .class-desc-select__control--is-focused {
        outline: 2px solid black !important;
      }
    }

    .locationName {
      span {
        font-size: 16px;
        font-weight: normal;
        color: #2b2d31;
      }
    }

    #extraInput {
      margin-top: 5px;
    }

    .check {
      margin-right: 0.3rem;
    }

    #newAddress {
      display: none;
      margin-left: 0;
    }

    .payPageItems {
      margin-left: 1rem;
    }

    .linkLike {
      position: relative;
      right: 0.55rem;
      // margin-top: 68px;
      // margin-bottom: 63px;
    }
    // // styl for binder
    .green {
      margin-left: 25%;
      font-size: 22px;
      font-weight: 600;
      color: #138f11;
      margin-bottom: 16px;
      p {
        margin: 0;
      }
    }
    .endForm {
      margin-left: 2rem;
    }
  }

  .newquote-sidebar {
    width: 19.5%;
    // max-width: 50%;
    padding-left: 0.2rem;
    background-color: #ff5a54;
    border-radius: 0 20px 20px 0;
    margin-top: 1rem;
    padding-bottom: 2rem;
    margin-right: 1rem;
    // font-family: "Overpass", sans-serif;
    .nav-item {
      outline: none;
      border-radius: none;
      .tick {
        margin-right: 0.08rem;
        margin-top: 1rem;
        float: right;
      }
      .nav-link {
        border: none;
        margin-bottom: 1rem;
        color: #2c2e33;
        // font-size: 16px;
        font-weight: 800;
        margin-left: 0.5rem;
        &.active {
          color: #0068c9;
          border: none;
          background-color: #efecec;
          border-radius: 20px 0 0 20px;
          width: 100%;
        }
        .sidebar-stagename {
          margin-left: 1.2rem;
        }
        &.sidebar-heading {
          margin-top: 30px;
          // color: #6b6666;
          font-weight: bolder;
        }
        .disabled {
          display: none;
        }
      }
    }
  }
}

/* Active Tab */
// .active-tab {
//   background-color: white;
//   border-radius: 20px;
//   width: 120%;
// }

// /* Inactive Tab */
// .inactive-tab {
//   background-color: #001e5a;
//   border-radius: 20px;
//   margin-right: 0;
// }

@media only screen and (max-width: 1350px) {
  .newquote-sidebar {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .newquote-sidebar {
    padding-left: 0.2rem;
    background-color: #ffffff;
    margin-top: 0rem !important;
    padding-bottom: 0rem !important;
    margin-right: 0rem !important;
  }
  .startDateWithExtensis {
    width: 100% !important;
  }
}

@media only screen and (max-width: 412px) {
  .price_modifier_form,
  .computation_table_responsive {
    flex-direction: column;
  }
  .excess-risk-margin {
    width: 100%;
  }
  .new-navbar {
    .navbar_responsive {
      flex-direction: row;
    }
    .pride-logo {
      margin-left: 0;
    }
  }
  #download-existing {
    .card {
      width: 24rem !important;
    }
  }
}

#downloadable {
  width: 90%;
  margin: auto;
  padding-top: 20px;
  font-size: 14px;
  strong {
    font-size: 16px;
  }
}

#downloadable {
  .sectionHolder {
    padding: 20px;
    margin: 30px auto;
  }
}

.home-page {
  //styling of the new home page
  padding-top: 5rem !important;
  #banMain {
    img {
      width: 100%;
    }

    h2 {
      font-family: "Merriweather", sans-serif;
      color: #2162ac;
      font-size: 50px;
      font-weight: 600;
      margin-top: 26px;
      margin-bottom: 16px;
    }

    h3 {
      font-weight: bolder;
      color: #2f3237;
      font-size: 20px;
      line-height: 1.5;
      font-weight: normal;
      margin-bottom: 36px;
    }
    .btn {
      color: white;
      // font-family: "Rubik", sans-serif;
      font-size: 22px;
      font-weight: normal;
      border-radius: 7.2px;
      color: #fff;
      background-color: #f0ad4e;
      border-color: #eea236;
      margin-bottom: 30px;
      box-shadow: 2px 4px 12px #eea236;
    }
  }
}

#itWorks {
  margin-right: 0px;
  margin-left: 0px;
  .row-3steps {
    // width: 100%;
    padding: 40px 0 40px 0;
    height: auto;
    background-color: #2162ac;
    // background-color: #2e3952;

    //3 steps logo
    .col-simplesteps {
      color: white;
      text-align: left;
      .simple-steps-wrapper {
        max-width: 300px;
      }
      h2 {
        text-align: left;
        font-size: 200px;
        margin-bottom: 0px;
        line-height: 1;
      }
      h3 {
        font-size: 44px;
        font-weight: 300;
        text-align: left;
      }
      img {
        margin: 1rem;
        position: relative;
        left: 15px;
        display: none;
      }
      .line-below-3 {
        width: 180px;
        height: 4px;
        opacity: 0.5;
        background-color: #ffffff;
        margin-bottom: 1rem;
        margin-top: 10px;
      }
    }

    //3-steps cards
    .col-simplesteps-cards {
      .card {
        width: 90%;
        color: white;
        background-color: transparent;
        .row {
          align-items: center;
          .col-card-image {
            height: auto;
            margin-right: 10px;
            .card-img {
              border: 0px !important;
              width: 97px;
              object-fit: contain;
              // img {
              //   margin-bottom: 35px;
              // }
            }
          }
          .col-card-text {
            height: auto;
            .card-body {
              text-align: left;
              .card-title {
                // font-family: "Rubik", sans-serif;
                font-size: 28px;
                font-weight: 500;
              }
              .card-text {
                margin-top: auto;
                // font-family: "Rubik", sans-serif;
                font-size: 20px;
                line-height: 1.5;
                font-weight: normal;
              }
              .learn-more {
                font-family: Arial, Helvetica, sans-serif;
                color: #2ed359;
                font-size: 16px;
                font-weight: bold;
              }
              .learn-more-arrow {
                // font-size: 2rem;
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }

  .row-our-mission {
    color: white;
    height: 420px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.4),
      rgba(0, 153, 255, 0.4) 100%
    );
    background-image: url("../images/home-mission-background.jpg");
    .col-md-12 {
      padding: 80px 80px 80px 120px;
      h1 {
        position: relative;
        right: 13px;
        font-family: "Merriweather", sans-serif;
        font-size: 100px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      h2 {
        // font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: normal;
        line-height: 1.5;
      }
    }
  }

  .row-everything-you-need {
    .col-md-12 {
      padding: 115px;
      h1 {
        color: #353a3f;
        font-family: "Merriweather", sans-serif;
        font-weight: bold;
        font-size: 44px;
      }
      .card-row {
        .card {
          background-color: #f8f9fb;
          height: auto;
          margin: 20px;
          text-align: center;
          img {
            height: 80px;
            width: auto;
            margin-top: 40px;
            margin-bottom: 30px;
          }
          .card-title {
            color: #33333c;
            // font-family: "Rubik", sans-serif;
            font-size: 20px;
            line-height: 1.3;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  .row-video {
    background-color: #e7f4ff;
    height: auto;
    justify-content: center;
    .col-md-12 {
      padding-left: 114px;
      padding-right: 114px;
      padding-top: 100px;
      h1 {
        color: #2c2e34;
        font-family: "Merriweather", sans-serif;
        font-size: 44px;
        font-weight: bold;
        line-height: 1.27;
      }
      h2 {
        color: #2c2e34;
        font-weight: normal;
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 40px;
      }
      iframe {
        margin-bottom: 120px;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  // body {
  //   background-color: lightblue;
  // }
  // #footerMain {
  //   position: absolute;
  //   bottom: 0;
  // }
}

#footerMain {
  // font-family: "Rubik", sans-serif;
  padding-top: 24px;
  padding-bottom: 20px;
  color: white;
  height: 4.75rem;
  // background-color: #2e3952;
  background-color: #18314e;
  padding-left: 100px;
  padding-right: 100px;
  .row-1 {
    div:nth-of-type(6) {
      margin-right: 10px;
    }
    justify-content: space-between;
    ul {
      padding-left: 2px;
      list-style: none;
      font-size: 16px;
    }
    .col-img {
      img {
        height: 75px;
        width: 130px;
      }
    }
    .col {
      a {
        color: white;
        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
  .t-and-c-row {
    // opacity: 0.6;
    font-size: 13px;
    line-height: 2.23;
    .col-sm-2 {
      a {
        color: white;
      }
    }
    .dull {
      opacity: 0.6;
    }
    .back-to-top {
      margin-right: 10px;
    }
  }
}

.quote-table {
  .head-row {
    align-items: center;
    justify-content: space-between;
  }
  .hr1 {
    color: #2c2e33;
    font-size: 26px;
    font-weight: 500;
    &.quote-table-company-name {
      font-family: "Overpass", sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .hr2 {
    color: #2c2e33;
    width: 70%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    span {
      color: #5f636b;
    }
  }
  .hr3 {
    margin-bottom: 1.5rem;
    color: #2c2e33;
  }
  .body-row {
    margin-top: 2rem;
    justify-content: center;
    .table {
      margin-bottom: 7rem;
      position: relative;
      right: 1rem;
      border-collapse: separate;
      border-spacing: 25px 0px;
      thead {
        th {
          color: #2c2e33;
          position: relative;
          // right: 0.6rem;
          font-size: 16px;
        }
      }
      tbody {
        font-size: 18px;
        td,
        th {
          width: 25%;
          border: 1px solid white;
          background-color: white;
          color: #2c2e33;
          font-size: 18px;
          font-weight: normal;
        }
        td {
          p {
            font-size: 22px;
            font-weight: 800;
            color: #1463ac;
            .best {
              font-size: 14px;
              margin-left: 1em;
              color: #038b00;
            }
            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }
          }
          input {
            margin: 0.5rem;
          }
        }
      }
    }
  }
  .section-form {
    input,
    select {
      border-radius: 0 20px 20px 20px;
    }
  }
}

#resDrop,
#workDrop,
#quotesDrop,
#profDrop {
  position: relative;
  display: none;
  padding: 0;
}
#resDrop .navDrop,
#quotesDrop .navDrop,
#workDrop .navDrop,
#profDrop .navDrop {
  background: #ffffff;
  position: absolute;
  z-index: 1;
  text-align: left;
  width: 180px;
  top: 10px;
  border-radius: 6px;
  box-shadow: 5px 5px 20px #888888;
}
#quotesDrop .navDrop {
  right: 0px;
  padding: 10px;
}
#profDrop .navDrop {
  width: 200px;
  right: 0px;
}
#profDrop hr {
  margin-top: 0;
  margin-bottom: 0;
}
#profDrop .navDrop li {
  display: inline-block;
  padding: 15px;
  color: #095d98;
  font-size: 1.5rem;
  font-style: initial;
}
#resDrop .navDrop li,
#quotesDrop .navDrop li,
#workDrop .navDrop li {
  display: block;
  color: #095d98;
  padding: 10px;
}
#Dropresources:hover #resDrop,
#Dropworks:hover #workDrop,
#Dropquotes:hover #quotesDrop,
#Dropprofile:hover #profDrop {
  display: block;
}
#Dropprofile:hover #profDrop {
  display: block;
}

#resDrop .navDrop img,
#workDrop .navDrop img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.tab-content {
  // height: 600px;
  min-height: calc(100vh - 9rem);
  display: flex;
  flex-direction: column;
  width: 100%;
}
// .tab-content-login,
// .tab-content-compliance {
//   min-height: calc(100vh - 15.75rem);
// }
// .tab-content-quote {
//   min-height: calc(100vh - 9rem);
// }
#loginButton {
  margin-left: 1rem;
  margin-right: 1rem;
}
#formLogin {
  height: auto;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}
#formChange {
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset {
  // min-height: 390px;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset,
#formLogin,
#formChange {
  border: 1px solid #f9f9f9;
}

#formReset:hover,
#formLogin:hover,
#formChange:hover {
  box-shadow: 0 10px 15px #888888;
  transition: all 0.5s;
}
#lgin {
  /* padding: 150px 0; */
  transition: all 0.5s;
  margin: auto;
}

.loginPage {
  .image-block {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    // margin-top: 60px;
    height: 600px;
    img {
      max-width: 100%;
    }
    .image-text-container {
      padding: 1rem 0;
      background: rgba(235, 41, 99, 0.6);
      position: absolute;
      .image-text {
        font-size: 3.125rem;
        font-weight: bolder;
        color: white;
      }
    }
  }
}

#chngpwd,
#fgtpwd {
  margin: auto;
}

.forSmall {
  display: none;
}

#loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
  z-index: 2002;
  top: 0;
  text-align: center;
  display: none;
}
#loader img {
  position: relative;
  width: 80px;
  top: 40%;
  margin: auto;
}

#formReset,
#formChange,
#formLogin {
  // margin-top: -16.5px;
  margin-bottom: 16px;
  .formTitle {
    font-size: 20px;
  }
  background-color: #e2f2ff;
  form {
    // margin-top: 10px;
    padding: 0 20px;
  }
  form input[type="password"],
  form input[type="email"],
  form input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  button {
    height: 35px;
    width: 100%;
    border-radius: 5px;
    background-image: linear-gradient(to right, #10c03e, #0eaf84);
    box-shadow: none;
    border-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .formBottomLinks {
    // padding: 20px 20px 50px 20px;
    justify-content: space-between;
    a {
      color: #2c2e33;
    }
  }

  .hover-over div {
    cursor: pointer;
  }
  .inptSideText {
    color: #5f636b;
  }
}
.formBottomLinks {
  padding: 20px;
  cursor: hand;
}

#changePassForm {
  input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

#quoteMain {
  @include cmprQts;
  background-color: #e2f2ff;
  #contentHolder {
    table {
      position: relative;
      right: 32px;
    }
  }
}

#resDrop,
#workDrop {
  .navDrop {
    padding: 0px;
    margin-left: -4rem;
    margin-top: 3rem;

    li {
      color: #0068c9;
      font-size: 13px;
      img {
        margin-left: 0px;
        margin-right: 8px;
        padding-left: 0px;
      }
    }
    a:hover {
      text-decoration: none;
    }
  }
}

.cmprQts-buttons {
  margin-bottom: 10px;
  text-align: center !important;
  // margin-left: 14rem;
}

.btnGen {
  margin-bottom: 36px;
}

#uwPortal {
  .fixedHeader {
    thead > tr > th {
      position: sticky;
      top: -1px;
      z-index: 3;
      background-color: white;
    }
    tbody > tr > td {
      vertical-align: middle;
    }
  }
  display: flex;
  height: calc(100vh - 5.7rem);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  .btn-sm {
    min-width: 60px;
    margin-bottom: 5px;
  }
  .custom-control-label::before {
    color: #fff;
    background-color: #e97d88;
    border-color: #e97d88;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
  }
  .custom-switch .custom-control-label::after {
    background-color: #fff;
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  }
}

.modal-full {
  width: 95%;
  max-width: none !important;
  height: 95%;
  iframe {
    min-height: 80vh;
  }
}

.dropDownMenuForEpp {
  width: -webkit-fill-available;
  label {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #333;
    margin-bottom: 0;
  }
  select {
    height: 35px;
    color: #2b2d31;
    font-size: 14px;
    padding-left: 5px;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    width: 200px;
  }
  .item-center {
    display: grid;
    //gap: 4px;
    align-items: center;
    justify-items: center;
  }
}
.cm-toggle {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  outline: 1px;
  cursor: pointer;
  margin-right: 30px;
}

/* To create surface of toggle button */
.cm-toggle:after {
  content: "";
  width: 30px;
  height: 15px;
  display: inline-block;
  background: #8d02767c;
  border-radius: 22px;
  clear: both;

  box-shadow: rgba(15, 13, 13, 0.6);
}

/* Contents before checkbox to create toggle handle */
.cm-toggle:before {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0.7px;
  top: 1.48px;
  border-radius: 50%;
  background: rgb(250, 248, 248);
}

/* Shift the handle to left on check event */
.cm-toggle:checked:before {
  left: 17px;
  box-shadow: 0px 0px 0px rgba(15, 13, 13, 0.6);
}
/* Background color when toggle button will be active */
.cm-toggle:checked:after {
  background: #23a023;
}

.bootstrapSwitchButtonRateMatch {
  .switch {
    width: 140px;
    border-radius: 150px;
  }
}
#naicsNumber::-webkit-outer-spin-button,
#naicsNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#naicsNumber[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 450px) {
  #formLogin {
    // height: auto;
    width: fit-content;
    // background: #ffffff;
    // margin: auto;
    // position: relative;
    // padding-top: 20px;
    // transition: all 0.5s;
  }
}
.dropZonesContainer {
  padding: 0 25px;
  .customDropZone {
    .dropzone {
      text-align: center;
      padding: 20px;
      border: 2px dashed #363333;
      background-color: #fafafa;
      color: #8a5656;
      cursor: pointer;
      border-radius: 20px;
    }
    li {
      background: none;
      .fw-bold {
        cursor: pointer;
      }
    }
  }
}

.popup {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10000;
  top: 0;
  text-align: center;
  display: none;
  background: rgba(0, 0, 0, 0.6);
}
.popup-container {
  font-family: MainFont, Arial, Georgia, sans-serif !important;
  background-color: #23b4e8;
  border-radius: 15px;
  box-shadow: 2px 4px 12px black;
  position: absolute;
  width: 500px;
  left: 50%;
  top: 50%;
  padding: 20px;
  color: white;
  transform: translate(-50%, -50%);
  .header-text {
    font-weight: bold;
  }
  .btn {
    border-radius: 7.2px;
    background-color: #ffd13f;
    border-color: #eea236;
    box-shadow: 2px 4px 12px #eea236;
    color: white;
    font-size: 1rem;
    font-weight: 800;
  }
}

.Extensis-Dashboard {
  .legend-placeholder {
    position: absolute;
    bottom: 25px;
    right: 10px;
  }
  .section3 > div {
    padding: 0;
  }
  .colored-box {
    .value {
      font-size: 18px;
    }
  }
  .big-value {
    .value {
      font-size: 30px;
    }
  }
  .btnConfirmAll {
    padding: 0.4rem 1rem;
  }
  .btnConfirm,
  .btnConfirmAll {
    // width: 100px;
    // height: 40px;
    border-radius: 5px;
    background-color: #f0ad4e;
    border-color: #eea236;
    box-shadow: none;
    color: white;
    font-size: 14px;
    font-weight: 800;
  }
  table tbody tr td {
    padding:4px 4px 4px 4px !important;
    
  }
  table tbody tr:first-child td {
    padding: 0px 10px 3px 3px !important;
    div{
      border-bottom: 1px solid black !important;
    }
  }
}

.class-desc-select__menu {
  width: 60vw !important;
  max-width: none !important;
}
.class-desc-select__option {
  width: auto !important;
}

.link-new-bound-status:hover {
  text-decoration: underline;
}

.textBlack {
  color: black !important;
}

#download-existing {
  table {
    tr {
      td {
        padding: 15px;
      }
    }
  }
  .react-datepicker-wrapper {
    input {
      border-radius: 10px;
      border: none;
    }
  }
  .btn {
    background-color: #cd0606;
    color: white;
  }
}
.download-heading,
.upload-heading {
  // background-color: #ff5a54;
  color: rgb(16, 16, 16);
  padding: 1.5rem;
  width: 95%;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: -30px;
  border-radius: 20px;
}

.tabs-for-dropzones {
  .nav-link {
    border: none;
    color: white;
    border-radius: 0;
    background-color: rgb(155, 153, 153);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .nav-link.active {
    background-color: #ff5a54;
  }
}

.extensis-grid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  width: 100%;
  .fw-bold {
    display: flex;
    #fw-bold-text {
      display: flex;
      align-items: center;
      width: 40px;
    }
  }
  thead {
    height: 60px;
    th {
      position: sticky !important;
      position: -webkit-sticky;
      top: 0;
      z-index: 100;
    }
  }
  tbody tr:nth-child(even) {
    background-color: #e2f2ff;
  }
  table {
    border-collapse: collapse;
  }
  .MuiToolbar-root,
  tfoot {
    display: none;
  }
  .MuiTableSortLabel-root.MuiTableSortLabel-active,
  .MuiTableSortLabel-root:hover {
    color: white;
  }
  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
    .MuiTableSortLabel-icon {
    color: white;
  }
}

.arrow1 {
  width: 10px;
}

// .line {
//   margin-left: 5px;
//   width: 4px;
//   background: rgb(8, 218, 8);
//   height: 10px;
//   float: top;
// }

.point {
  margin-top: 2px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid rgb(11, 238, 11);
  float: top;
}

.tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 7px;
  padding: 5px 5px;
  position: relative;
  z-index: 1;
  bottom: 200%;
  // left: -50%;
  margin-left: -90px;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 15px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

#tooltip-legend:hover {
  .tooltiptext {
    visibility: visible;
  }
}

.compliance-panel {
  h5 {
    text-align: center;
  }
  .carier-info-table {
    // margin-inline: auto;
    margin-top: 70px;
    margin-bottom: 20px;
    width: 310px;
    border: 1px solid rgb(248, 18, 18);
    thead {
      height: 40px;
      text-align: center;
    }
    tbody {
      text-align: center;
      font-weight: 400;
    }
    tbody > tr:first-child {
      border: 1px solid rgb(248, 18, 18);
    }
    td {
      border-radius: 3px;
      padding: 3px 20px;
      margin-bottom: 25px;
      // border: 1px solid rgb(248, 18, 18);
    }
    th {
      // background-color: #6083ace5;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      width: 50%;
      border: 1px solid rgb(248, 18, 18);
    }
  }
}
#compliancePortal {
  .row {
    margin-bottom: 70px !important;
  }
  select {
    // border: 1px solid red;
    width: 190px;
    height: 35px;
  }
  b {
    font-size: 25px;
    color: #7e777a;
    letter-spacing: 1.5px;
  }
  .multi-select-state {
    button {
      border: 1px solid red;
      width: 190px;
      height: 35px;
    }
  }
  .card-body {
    .react-select-checkbox {
      text-align: left;
    }
    table {
      border-collapse: separate;
      border-spacing: 1em 2rem;
      td {
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: end;
      }
    }
    .btn-warning {
      background-color: #ffc108 !important;
      width: 163px;
      margin-left: 5px;
      color: white;
      height: 40px;
    }
    .btn-danger {
      background-color: #cb0e17;
      margin-left: 25px;
      width: 163px;
      height: 40px;
    }
  }
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.MuiTableSortLabel-root {
  color: white !important;
}

.MuiTableSortLabel-active {
  font-weight: 700 !important;
  color: #ff5a54 !important;
  .MuiTableSortLabel-icon {
    color: white !important;
  }
}

.costEppInputValue,
.chargeEppInputValue {
  display: flex !important;
  justify-content: center;
}
.chargeEppInputValue .text-center,
.costEppInputValue .text-center {
  width: 140px;
}

.epphHeaderRow {
  th:first-child {
    width: 70%;
    padding: 15px;
    border-radius: 15px 0 0 15px;
  }
  th:last-child {
    width: 30%;
    padding: 15px;
    border-radius: 0 15px 15px 0;
  }
}

.epphBodyRow {
  td {
    padding: 5px 25px;
  }
}

.epphBodyRow:first-child td:first-child {
  border-radius: 15px 0 0 0;
}

.epphBodyRow:first-child td:last-child {
  border-radius: 0 15px 0 0;
}

// .epphBodyRow:last-child td:first-child {
//   border-radius: 0 0 0 15px;
// }
// .epphBodyRow:last-child td:last-child {
//   border-radius: 0 0 15px 0;
// }

@media screen and (min-width: 768px) and (max-width: 992px) {
  //login page
  .extensis-image {
    height: 2.4rem !important;
    margin-top: 1.8rem !important;
  }
  .insurecomp-image {
    height: 4.9rem !important;
  }
  .loginPage {
    .image-block {
      height: 550px;
    }
  }
  .image-text {
    font-size: 2.125rem !important ;
  }
  .mobile-font-size {
    // font-size: 0.6rem !important;
    display: grid !important;
    grid-template-columns: repeat(1, 3fr);
    row-gap: 20px;
  }
  // .nav-font-mobile,.tab-font-mobile{
  //   font-size: 0.8rem !important;
  // }
  // .mobile-font-size{
  //   font-size: 0.6rem !important;
  // }
  .get-button {
    margin-bottom: 2rem;
    margin-top: -1rem;
  }
  .location-mobile {
    flex-direction: column !important;
  }
  .exposure-del-btn {
    margin-top: -3rem;
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 625px) and (max-width: 767px) {
  .extensis-image {
    height: 2rem !important;
    margin-top: 1.8rem !important;
  }
  .insurecomp-image {
    height: 4.3rem !important;
  }
  .loginPage {
    .image-block {
      height: 450px;
    }
  }
  .pride-logo {
    margin-left: 1.2rem !important;
  }
  .insurecomp-logo {
    margin-right: 1.2rem !important;
  }
  .image-text {
    font-size: 1.7rem !important ;
  }
  // .nav-font-mobile,.tab-font-mobile{
  //   font-size: 1rem !important;
  // }
  .mobile-font-size {
    // font-size: 0.6rem !important;
    display: grid !important;
    grid-template-columns: repeat(1, 3fr);
    row-gap: 20px;
  }
  // .MuiTableCell-body{
  //   font-size: 10px !important;
  // }
  .get-button {
    margin-bottom: 2rem;
    margin-top: -1rem;
  }
  .location-mobile {
    flex-direction: column !important;
  }
}

@media screen and (min-width: 521px) and (max-width: 624px) {
  .extensis-image {
    height: 2rem !important;
    margin-top: 1.8rem !important;
  }
  .insurecomp-image {
    height: 4.3rem !important;
  }
  .loginPage {
    .image-block {
      height: 450px;
    }
  }
  .pride-logo {
    margin-left: 1.2rem !important;
  }
  .insurecomp-logo {
    margin-right: 1.2rem !important;
  }
  .image-text {
    font-size: 1.7rem !important ;
  }
  // .nav-font-mobile,.tab-font-mobile{
  //   font-size: 0.7rem !important;
  // }
  .mobile-font-size {
    // font-size: 0.6rem !important;
    display: grid !important;
    grid-template-columns: repeat(1, 3fr);
    row-gap: 20px;
  }
  // .MuiTableCell-body{
  //   font-size: 10px !important;
  // }
  .get-button {
    margin-bottom: 2rem;
    margin-top: -1rem;
  }
  .location-mobile {
    flex-direction: column !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 520px) {
  .extensis-image {
    height: 1.6rem !important;
    margin-top: 1.8rem !important;
  }
  .insurecomp-image {
    height: 3.5rem !important;
  }
  .loginPage {
    .image-block {
      height: 300px;
    }
  }
  .image-text {
    font-size: 1.3rem !important ;
  }
  .nav-font-mobile,
  .tab-font-mobile {
    font-size: 0.6rem !important;
  }
  .tab-font-mobile {
    margin-left: 0.5rem 0.5rem !important;
  }
  .new-navbar {
    ul {
      li {
        margin-right: 0px !important;
      }
    }
  }
  .mobile-font-size {
    // font-size: 0.6rem !important;
    display: grid !important;
    grid-template-columns: repeat(1, 3fr);
    row-gap: 20px;
  }
  // .MuiTableCell-body{
  //   font-size: 9px !important;
  // }
  // .MuiTablePagination-toolbar{
  //   font-size: 5px !important;
  // }
  .get-button {
    margin-bottom: 2rem;
    margin-top: -1rem;
  }
  .location-mobile {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 410px) {
  .pride-logo {
    margin-left: 0.2rem !important;
  }
  .insurecomp-logo {
    margin-right: 0.2rem !important;
  }
}
nav {
  background-color: #1463ac;
  border-radius: 20px;
  color: white;
  // height: 50px;
  // padding: 0 16px;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  /* fade in checked menu */
}

nav .m-menu__checkbox {
  display: none;
}

nav label.m-menu__toggle {
  cursor: pointer;
}

nav .m-menu {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 360px;
  width: calc(100vw - 30px);
  // height: 100vh;
  height: 100%;
  // height: calc(var(--vh, 1vh) * 100);
  -moz-transform: translate3d(-450px, 0, 0);
  -o-transform: translate3d(-450px, 0, 0);
  -ms-transform: translate3d(-450px, 0, 0);
  -webkit-transform: translate3d(-450px, 0, 0);
  transform: translate3d(-450px, 0, 0);
  -moz-transition: transform 0.35s;
  -o-transition: transform 0.35s;
  -webkit-transition: transform 0.35s;
  transition: transform 0.35s;
  z-index: 12;
  overflow: hidden;
  background-color: #fff;
}

nav .m-menu__overlay {
  background-color: rgba(103, 103, 103, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: 12;
  display: none;
}

nav .m-menu__header {
  padding-left: 0px;
  height: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

nav .m-menu__header span {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

nav .m-menu .m-menu {
  -moz-transform: translate3d(480px, 0, 0);
  -o-transform: translate3d(480px, 0, 0);
  -ms-transform: translate3d(480px, 0, 0);
  -webkit-transform: translate3d(480px, 0, 0);
  transform: translate3d(480px, 0, 0);
}

nav .m-menu .m-menu label.m-menu__toggle {
  display: flex;
  border-bottom: 0;
  padding: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

nav .m-menu__checkbox:checked ~ .m-menu__overlay {
  display: block;
}

nav .m-menu__checkbox:checked ~ .m-menu {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.m-menu__toggle {
  padding-left: 25px;
}
.headerSubMenuMainContent {
  display: flex;
  list-style-type: none;
}

@media only screen and (max-width: 992px) {
  .newquote-sidebar {
    width: 90% !important;
    background-color: white !important;
  }
  .newquote-mainbody .newquote-content {
    width: 100% !important;
  }
  .headerSubMenuContent {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 37px;
    font-size: medium;
    font-weight: 700;
  }
  .headerSubMenuContent li {
    margin-bottom: 1rem;
    padding: 2px;
  }
  .headerSubMenuMainContent {
    display: contents;
  }
  .upload-guide {
    font-size: large;
    padding-left: 25px;
  }
}

// .Api_navbar {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
// }

// .Api_navbar > div {
//   margin-bottom: 10px; /* Optional: Adjust spacing between each section */
// }

// .Api_navbar button,
// .Api_navbar input,
// .Api_navbar select {
//   margin-right: 10px; /* Optional: Adjust spacing between each input */
// }

// .Api_navbar .search-input {
//   padding: 8px 12px 8px 12px; /* Adjust padding to fit the icon */
//   border: 5px solid #ccc;
//   border-radius: 20px;
//   font-size: 16px;
//   outline: none;
//   background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"/><line x1="21" y1="21" x2="16.65" y2="16.65"/></svg>');
//   background-size: 24px; /* Adjust size of the icon */
//   background-repeat: no-repeat;
//   background-position: right 10px center; /* Adjust position of the icon */
// }

// .Api_navbar .search-input:focus {
//   border-color: #007bff;
//   box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
// }

// .Api_ select {
//   padding: 8px 12px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 16px;
//   background-color: #fff;
//   appearance: none; /* Removes default arrow */
//   outline: none;
// }

// .Api_navbar select:focus {
//   border-color: #007bff;
//   box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
// }

// /* Style the dropdown arrow */
// .Api_navbar select::-ms-expand {
//   display: none;
// }

// .Api_navbar select:after {
//   content: "\25BC";
//   position: absolute;
//   top: 50%;
//   right: 10px;
//   transform: translateY(-50%);
//   pointer-events: none;
// }

// .Api_navbar select:hover,
// .Api_navbar select:focus {
//   background-color: #f0f0f0;
// }

// .Api_navbar button {
//   padding: 8px 16px;
//   margin-right: 5px;
//   border: 1px solid #007bff;
//   background-color: #007bff;
//   color: #fff;
//   border-radius: 4px;
//   cursor: pointer;
//   outline: none;
//   transition: background-color 0.3s, color 0.3s;
// }

// .Api_navbar button:hover {
//   background-color: #0056b3;
// }

// .Api_navbar button:disabled {
//   background-color: #ccc;
//   border-color: #ccc;
//   color: #777;
//   cursor: not-allowed;
// }

// .Api_navbar button.active {
//   background-color: #0056b3;
// }

// .Api_navbar button:first-child {
//   margin-right: 10px; /* Adjust spacing between buttons */
// }

// /* Optional: Add styles for the Previous and Next buttons */
// .Api_navbar button:first-child::before,
// .Api_navbar button:last-child::after {
//   content: "";
//   display: inline-block;
//   vertical-align: middle;
//   margin-right: 5px; /* Adjust spacing between icon and text */
// }

// .Api_navbar button:first-child::before {
//   content: "◄"; /* Unicode character for left arrow */
// }

// .Api_navbar button:last-child::after {
//   content: "►"; /* Unicode character for right arrow */
// }

// .api-container {
//   .table {
//     width: 100%;
//     border-collapse: separate;
//     border-spacing: 0 10px; /* Adjust spacing between rows */
//   }

//   .table th,
//   .table td {
//     padding: 15px;
//     text-align: left; /* Ensure text alignment */
//   }

//   .table th {
//     background-color: #f8f9fa; /* Optional: Different background color for header */
//     font-weight: bold;
//   }

//   .table tbody tr {
//     background-color: #fff;
//     border-radius: 20px; /* Rounded corners on bottom */
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow effect */
//     transition: background-color 0.3s;
//   }

//   .table tbody tr:hover {
//     background-color: #f0f0f0; /* Change background color on hover */
//   }
// }

#download-existing {
  .card {
    width: 30rem;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #edecec;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .card-img-top {
    width: 70%;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: auto;
    // margin-bottom: 15px;
    // margin-top: 15px;
  }
}

.download_folder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}

#profileSaveButton {
  border-radius: 20px;
  background-color: #1463ac;
  box-shadow: none;
  border: none;
}

.no-gutters {
  background-color: #ececec;
}

.Quote-head th {
  background-color: #ececec;
}

.pink-arrow {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="pink" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.293 7.293a1 1 0 0 1 1.414 0L10 9.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.5rem top 50%;
  padding-right: 1.5rem; /* Adjust as needed */
}

/* Styles for table head */
.table thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Styles for table body */
.table tbody {
  margin-top: 10px; /* Adjust the margin as needed */
}

.table tbody tr {
  border-radius: 10px;
  background-color: snow;
}

/* Styles for input fields */
.table input[type="text"],
.table input[type="number"] {
  background-color: rgb(189, 186, 186);
  border: none;
  border-radius: 20px;
}

.side_bar,
#download-existing {
  background-color: #ececec;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
}

.selected {
  background-color: #ff5a54;
  border-radius: 0 20px 0 20px;
  height: 100%;
}

.headerSubMenuContent li {
  margin-right: 10px;
}

.From_Download {
  margin: 10px;
}

.horizontal_row {
  margin-top: -20px;
}
